<form [formGroup]="formGroup" [bitSubmit]="submit">
  <bit-dialog dialogSize="large" [loading]="loading$ | async">
    <span bitDialogTitle
      >{{ "logInWithPasskey" | i18n }}
      <span class="tw-text-sm tw-normal-case tw-text-muted">{{ "newPasskey" | i18n }}</span>
    </span>
    <ng-container bitDialogContent>
      <ng-container *ngIf="currentStep === 'userVerification'">
        <ng-container formGroupName="userVerification">
          <app-user-verification
            formControlName="secret"
            [(invalidSecret)]="invalidSecret"
          ></app-user-verification>
        </ng-container>
      </ng-container>

      <div *ngIf="currentStep === 'credentialCreation'" class="tw-flex tw-flex-col tw-items-center">
        <bit-icon [icon]="Icons.CreatePasskeyIcon" class="tw-mb-6"></bit-icon>
        <h3 bitTypography="h3">{{ "creatingPasskeyLoading" | i18n }}</h3>
        <p bitTypography="body1">{{ "creatingPasskeyLoadingInfo" | i18n }}</p>
      </div>

      <div
        *ngIf="currentStep === 'credentialCreationFailed'"
        class="tw-flex tw-flex-col tw-items-center"
      >
        <bit-icon [icon]="Icons.CreatePasskeyFailedIcon" class="tw-mb-6"></bit-icon>
        <h3 bitTypography="h3">{{ "errorCreatingPasskey" | i18n }}</h3>
        <p bitTypography="body1">{{ "errorCreatingPasskeyInfo" | i18n }}</p>
      </div>

      <div *ngIf="currentStep === 'credentialNaming'" formGroupName="credentialNaming">
        <h3 bitTypography="h3">{{ "passkeySuccessfullyCreated" | i18n }}</h3>
        <p bitTypography="body1">
          {{ "customPasskeyNameInfo" | i18n }}
        </p>
        <bit-form-field class="!tw-mb-0">
          <bit-label>{{ "name" | i18n }}</bit-label>
          <input type="text" bitInput formControlName="name" appAutofocus />
          <bit-hint>{{
            "charactersCurrentAndMaximum"
              | i18n: formGroup.value.credentialNaming.name.length : NameMaxCharacters
          }}</bit-hint>
        </bit-form-field>
        <bit-form-control *ngIf="pendingCredential?.supportsPrf" class="!tw-mb-0 tw-mt-6">
          <input type="checkbox" bitCheckbox formControlName="useForEncryption" />
          <bit-label>{{ "useForVaultEncryption" | i18n }}</bit-label>
          <bit-hint>{{ "useForVaultEncryptionInfo" | i18n }}</bit-hint>
        </bit-form-control>
      </div>
    </ng-container>
    <ng-container bitDialogFooter>
      <button type="submit" bitButton bitFormButton buttonType="primary">
        <ng-container *ngIf="currentStep === 'userVerification'">
          {{ "continue" | i18n }}
        </ng-container>
        <ng-container *ngIf="currentStep === 'credentialCreation'">
          {{ "continue" | i18n }}
        </ng-container>
        <ng-container *ngIf="currentStep === 'credentialCreationFailed'">
          {{ "tryAgain" | i18n }}
        </ng-container>
        <ng-container *ngIf="currentStep === 'credentialNaming'">
          {{ ((hasPasskeys$ | async) ? "save" : "enable") | i18n }}
        </ng-container>
      </button>
      <button type="button" bitButton bitFormButton buttonType="secondary" bitDialogClose>
        {{ "cancel" | i18n }}
      </button>
    </ng-container>
  </bit-dialog>
</form>
