<form
  [bitSubmit]="submitForm.bind(null, false)"
  [appApiAction]="formPromise"
  [formGroup]="formGroup"
>
  <ng-container *ngIf="!validatedEmail">
    <div class="tw-mb-3">
      <bit-form-field>
        <bit-label>{{ "emailAddress" | i18n }}</bit-label>
        <input bitInput type="email" formControlName="email" appAutofocus />
      </bit-form-field>
    </div>

    <div class="tw-mb-3 tw-flex tw-items-start">
      <bit-form-control class="tw-mb-0">
        <input type="checkbox" bitCheckbox formControlName="rememberEmail" />
        <bit-label>{{ "rememberEmail" | i18n }}</bit-label>
      </bit-form-control>
    </div>

    <div class="tw-mb-3">
      <button
        bitButton
        type="submit"
        buttonType="primary"
        class="tw-w-full"
        (click)="validateEmail()"
      >
        <span> {{ "continue" | i18n }} </span>
      </button>
    </div>

    <div class="tw-mb-3 tw-flex tw-flex-col tw-items-center tw-justify-center">
      <p class="tw-mb-3">{{ "or" | i18n }}</p>

      <a
        bitLink
        block
        linkType="primary"
        routerLink="/login-with-passkey"
        (mousedown)="$event.preventDefault()"
      >
        <span><i class="bwi bwi-passkey"></i> {{ "logInWithPasskey" | i18n }}</span>
      </a>
    </div>

    <hr />

    <p class="tw-m-0 tw-text-sm">
      {{ "newAroundHere" | i18n }}
      <!-- Two notes:
           (1) We check the value and validity of email so we don't send an invalid email to autofill
           on load of register for both enter and mouse based navigation
           (2) We use mousedown to trigger navigation so that the onBlur form validation does not fire
           and move the create account link down the page on click which causes the user to miss actually
           clicking on the link. Mousedown fires before onBlur.
      -->
      <a
        bitLink
        [routerLink]="registerRoute$ | async"
        [queryParams]="emailFormControl.valid ? { email: emailFormControl.value } : {}"
        (mousedown)="goToRegister()"
        >{{ "createAccount" | i18n }}</a
      >
    </p>
  </ng-container>

  <div [ngClass]="{ 'tw-hidden': !validatedEmail }">
    <div class="tw-mb-6 tw-h-28">
      <bit-form-field class="!tw-mb-1">
        <bit-label>{{ "masterPass" | i18n }}</bit-label>
        <input type="password" bitInput #masterPasswordInput formControlName="masterPassword" />
        <button type="button" bitSuffix bitIconButton bitPasswordInputToggle></button>
      </bit-form-field>
      <a
        bitLink
        class="tw-mt-2"
        routerLink="/hint"
        (mousedown)="goToHint()"
        (click)="saveEmailSettings()"
        >{{ "getMasterPasswordHint" | i18n }}</a
      >
    </div>

    <div [hidden]="!showCaptcha()">
      <iframe id="hcaptcha_iframe" height="80" sandbox="allow-scripts allow-same-origin"></iframe>
    </div>

    <div class="tw-mb-3 tw-flex tw-space-x-4">
      <button bitButton buttonType="primary" bitFormButton type="submit" [block]="true">
        <span> {{ "loginWithMasterPassword" | i18n }} </span>
      </button>
    </div>

    <div class="tw-mb-3" *ngIf="showLoginWithDevice && showPasswordless">
      <button
        bitButton
        type="button"
        [block]="true"
        buttonType="secondary"
        (click)="startAuthRequestLogin()"
      >
        <span> <i class="bwi bwi-mobile"></i> {{ "loginWithDevice" | i18n }} </span>
      </button>
    </div>

    <div class="tw-mb-3">
      <a
        routerLink="/sso"
        [queryParams]="{ email: formGroup.value.email }"
        (click)="saveEmailSettings()"
        bitButton
        buttonType="secondary"
        class="tw-w-full"
      >
        <i class="bwi bwi-provider tw-mr-2"></i>
        {{ "enterpriseSingleSignOn" | i18n }}
      </a>
    </div>

    <hr />

    <div class="tw-m-0 tw-text-sm">
      <p class="tw-mb-1">{{ "loggingInAs" | i18n }} {{ loggedEmail }}</p>
      <a bitLink [routerLink]="[]" (click)="toggleValidateEmail(false)">{{ "notYou" | i18n }}</a>
    </div>
  </div>
</form>
